/* SkeletonLoader.css */
.skeleton-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
    margin-top: 140px;
  }
  
  .skeleton-previous-card {
    background-color: #ddd;
    border-radius: 8px;
    width: 100%;
    max-width: 220px;
    height: 140px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-previous-card::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, transparent 100%);
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    0% {
      left: -150px;
    }
    50% {
      left: 100%;
    }
    100% {
      left: 100%;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .skeleton-card {
      max-width: 100%;
      height: 120px;
    }
  }
  
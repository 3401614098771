.main_sidemenu {
  width: 220px;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  left: 0px;
  padding-top: 0px;
  transition: all 0.5s;
  z-index: 99;
}

body {
  background-color: #f5f6fa;
}

.list_main_menu {
  margin-top: 15px;
}

.close_sidemenu {
  display: block;
  position: absolute;
  top: 24px;
  right: -28px;
  z-index: 9999;
}

.pos-rel {
  position: relative;
}

.sidemenu_logo {
  text-align: center;
}

.sidemenu_logo img {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s;
}

.sidemenu_logo img {
  padding: 15px;
}

.main_main_ul_list {
  padding-left: 0px;
  cursor: pointer;
}

.main_main_ul_list li {
  position: relative;
  margin-bottom: 5px;
  list-style-type: none;
}

.main_main_ul_list li.active {
  /* border-left:6px solid #265936; */
}

.page_list {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: unset;
  color: #202224;
}

.main_main_ul_list li .page_list.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background-color: #265936;
  border-radius: 0px 4px 4px 0px;
}

.main_main_ul_list li .page_list.active .inner_page_list {
  background-color: #DEEDDA;
  color: #265936;
}

/* .main_main_ul_list li .page_list.active .inner_page_list {
  background-color: #9e7c0c;
  color: #fff;
} */

.inner_page_list {
  display: flex;
  padding-top: 10px;
  align-items: center;
  padding-right: 8px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-radius: 50px;
}

.main_menu_name {
  font-size: 14px;
  font-weight: 500;
}

.main_menu_name a {
  text-decoration: unset;
  color: #fff;
}

.main_menu_icon {
  margin-right: 15px;
  font-size: 20px;
  margin-left: 5px;
}

/* sm side nav  */
.sidebar_open * {
  transition: all 0.5s;
}
.sidebar_open .main_sidemenu {
  width: 80px;
}

.sidebar_open .main_menu_name {
  display: none;
}

.sidebar_open .sidemenu_logo img {
  padding: 6px;
  width: 60px;
  object-fit: cover;
  height: 60px;
  object-position: 5px;
}
.sidebar_open .page_container,
.sidebar_open .header_container {
  margin-left: 80px;
}
.sidebar_open .inner_page_list {
  padding-left: 15px;
}
.sidebar_open .main_menu_icon {
  margin-left: 0;
}
.logout_modal .modal-content {
  border-radius: 30px;
}

.logout_modal .modal-content .modal-body h2 {
  font-size: 20px;
  color: #202224;
}
.logout_modal .modal-footer .btn-primary {
  background: #265936 !important;
  border: 0 !important;
  padding: 6px 30px !important;
  font-weight: 600;
}
.logout_modal .modal-footer .btn-secondary {
  background: #c3c3c345 !important;
  border: 0 !important;
  padding: 6px 30px !important;
  color: #265936;
  font-weight: 600;
}
.modal-backdrop.fade.show {
  opacity: 0.1;
}

.sub_title {
  text-decoration: none;
  color: #9e7c0c !important;
}

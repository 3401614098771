.chart_card {
  border-radius: 14px;
}

.chart_card h3 {
  font-size: 18px;
  color: #464255;
  font-weight: 700;
}

.glitch_list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 20px;
}

.glitch_list ul li {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}

.glitch_title {
  font-size: 14px;
  color: #265936;
}

.glitch_count {
  color: #232325;
  font-size: 12px;
}

.color-1 {
  color: #ffb545;
}

.color-2 {
  color: #5a2391;
}

.color-3 {
  color: #00bebe;
}

.color-4 {
  color: #f32d2d;
}

.color-5 {
  color: #17df1f;
}

.color-6 {
  color: #ffb545;
}

.color-7 {
  color: #5a2391;
}

.color-8 {
  color: #00bebe;
}

.page_title.title_wise {
  padding-bottom: 5px;
}

.mobile_filter_icon.classes {
  position: absolute;
  z-index: 9;
  top: 20px;
  right: 22px;
}

.filter_box {
  display: none;
}

.mob_fills_icon {
  display: none;
}

.report_data-list {
  list-style: none;
  overflow-y: auto;
}

.report_data-list li a {
  text-decoration: none;
  font-size: 14px;
  color: #232325;
  background: #e9e9e95e;
  padding: 8px 9px;
  border-radius: 10px;
}

.bar_chart {
  min-height: 400px;
}

.session_count-dropdown {
  position: absolute;
  right: 20px;
  top: 10px;
}

.report_card {
  overflow-y: auto;
}

.rss_tab li a {
  border: 0 !important;
  border-radius: 50px !important;
  font-size: 14px;
  color: #464255 !important;
  font-weight: 700;
  background-color: #d9cbad59 !important;
}
.rss_tab {
  gap: 10px;
  justify-content: start;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.rss_tab li {
  width: 45%;
  text-align: center;
  margin-top: 6px;
}
.rss_tab li a.active {
  background-color: #9e7c0c !important;
  color: #fff !important;
}
.rss_data a {
  text-decoration: unset !important;
  margin-bottom: 10px;
  border-left: 6px solid #a78927;
  padding-left: 10px;
}
.rss_data a h3 {
  font-size: 16px;
  font-weight: 300;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rss_data a h3:hover {
  color: #a78927;
}

@media only screen and (max-width: 768px) {
  .bar_chart {
    min-height: auto;
  }
  .filter_box {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    right: 0px;
    top: 0px;
    z-index: 999;
    padding: 20px;
    display: block;
  }

  .filt_head_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .filt_content_box {
    margin-top: 20px;
  }

  .filt_label {
    font-size: 14px;
    font-weight: 600;
    color: #265936;
    margin-bottom: 5px;
  }

  .fills_control {
    font-size: 14px;
  }

  .mob_padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mob_fil_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px;
  }

  .mob_fills_icon {
    display: block;
  }
  .rss_tab li a {
    font-size: 12px !important;
  }
  .rss_data a h3 {
    font-size: 12px !important;
  }
  .report_card {
    height: auto !important;
  }
  .chart_card canvas label {
    font-size: 10px;
  }
  .profile_form select {
    font-size: 10px !important;
    padding: 12px 7px !important;
    border-radius: 10px !important;
    background: transparent;
    border-color: #dfeaf2;
    width: 100% !important;
  }
  .mobile_input {
    width: 100% !important;
  }
  .report-optional {
    right: 0;
    left: 75%;
  }
  .chart_card .filter_col {
    margin-top: 20px;
  }
  .chart-line {
    /* This will horizontally center the chart */
    width: 100% !important; /* Set the desired width */
    height: 350px !important;
  }
}

.chart-container {
  /* Set the desired height */
}
.chart-line {
  /* This will horizontally center the chart */
  width: 100% !important; /* Set the desired width */
  height: 500px !important;
}
small {
  color: #9e7c0c;
  font-weight: 600;
}

.show_btn {
  background-color: #9e7c0c !important;
  color: #fff !important;
  border: none;

  padding: 10px 10px;
  position: sticky;
  bottom: 0;
  width: 100%;
}

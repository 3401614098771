/* styles.css */
.tabs {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 8px 20px;
  padding-top: 8px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: white;
  }
  
  .tabs button {
    background-color: #d9cbad59 !important;
    border: 0 !important;
    border-radius: 50px !important;
    color: #464255 !important;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 35px;
  }
  
  .tabs button:hover {
    background-color: #e0e0e0;
  }
  
  .tabs button:focus {
    outline: none;
  }
  
  .tabs button.active {
    background-color: #9e7c0c !important;
    color: #fff !important;

  }
  
  .content {
    margin-top: 20px;
    
  }
  .content .rss_tab a{
    border-left: 6px solid #a78927;
    margin-bottom: 10px;
    padding-left: 10px;
    text-decoration: none !important;
    text-decoration: initial !important;
    margin-left: 15px;
    color: black;  
  }
  .content .rss_tab a:hover{
    color: #a78927;
  
  }
  .rss_tab{
    justify-content: start;
  }
.skeleton-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .skeleton-image {
    width: 100%;
    height: 200px;
    background: #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    animation: shimmer 1.5s infinite;
  }
  
  .skeleton-text {
    width: 100%;
  }
  
  .skeleton-line {
    height: 20px;
    background: #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    animation: shimmer 1.5s infinite;
  }
  
  .skeleton-line.short {
    width: 60%;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  
.filter_container {
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 0px;
    align-items: stretch;
    border-radius: 12px;
    /* gap: 20px; */
    border: 1px solid #D5D5D5;
    position: relative;
    z-index: 4;
}

.filter_container .filter_col {
    width: 100%;
    position: relative;
    padding: 8px 20px 8px 10px;
    font-size: 14px;
    color: #202224;
}

.filter_container .filter_col:not(:last-child)::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    box-shadow: -0.1px 0px 0px 0.2px #979797;
    height: 100%;
}


/* .filter_col select,
.filter_col input {
    border: unset;
    background: transparent;
    box-shadow: unset !important;
    cursor: pointer;
    font-size: 14px;
} */

.reset_filter {
    color: #EA0234 !important;
    cursor: pointer;
}

/* .filter_col select,
.filter_col input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../assets/images/select_arrow.png');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px !important;
} */

.datepicker .table-condensed {
    width: 310px;
}

.datepicker .table-condensed thead tr:nth-child(2) th {
    border-bottom: 1px solid #ddd;
    padding: 12px 0px;
    position: relative;
}

.datepicker .table-condensed thead tr th {
    text-align: center;
    cursor: pointer;
}

.datepicker .datepicker-days .active.day {
    background: #265936 !important;
}

.datepicker tbody tr td .month.focused,
.datepicker tbody tr td .month.focused.active {
    background: #265936 !important;
    color: #fff !important;
}

.datepicker tbody tr td .month.active {
    background: #EEEEEE !important;
    color: #202224 !important;
}

.datepicker .table-condensed tbody tr td {
    width: 30px;
    height: 35px;
    font-size: 14px;
}

.datepicker {
    border-radius: 22px !important;
    box-shadow: 0px 5px 40px #a9a9a963;
}

.datepicker-dropdown.datepicker-orient-bottom:after,
.datepicker-dropdown:before {
    display: none !important;
}

.datepicker .table-condensed thead tr th.dow {
    width: 30px;
    height: 35px;
    font-size: 14px;
}

.datepicker .table-condensed thead tr th.prev:hover,
.datepicker .table-condensed thead tr th.next:hover {
    background-color: transparent !important;
}

.datepicker .table-condensed thead tr:nth-child(2) th.prev::before {
    content: "";
    background: url("../../assets/images/arrow.svg");
    position: absolute;
    left: 0px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-size: 8px;
    background-repeat: no-repeat;
    background-color: #E7E9EE;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 6px 6px;
    border-radius: 8px;
}

.datepicker .table-condensed thead tr:nth-child(2) th.next::before {
    content: "";
    background: url("../../assets/images/arrow.svg");
    position: absolute;
    right: 0px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-size: 8px;
    background-repeat: no-repeat;
    background-color: #E7E9EE;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: 6px 6px;
    transform: rotate(180deg);
    border-radius: 8px;
}

.videos_tab li a {
    border-radius: 50px !important;
    padding: 7px 22px;
    border: 0 !important;
    color: #265936 !important;
    font-weight: 600;
    font-size: 14px;
}

.video_blog-card {
    background: #fff;
    border-radius: 20px;
    padding: 15px 15px;
}

.video_banner img {
    width: 100%;
    border-radius: 20px;
    height: 200px;
    object-fit: cover;
}

.video_title {
    color: #9E7C0C;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.video_duration {
    color: #5C5C5C;
    font-size: 12px;
}

.action_list {
    list-style: none;
    display: flex;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.video_date {
    color: #265936;
    font-size: 14px;
    font-weight: 700;
}

.like_video {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    color: #9E7C0C;
}

.like_video input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.thump_like {
    background-color: #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 6px 18px;
    cursor: pointer;
    font-size: 14px;
    color: #265936;
    font-weight: 700;
}

.thump_like .like_icon {
    font-size: 20px;
    margin-top: -3px;
}

.thump_like input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
body{
    background-color: #F5F6FA;
}



.filter_box {
    display: none;
}
.mob_fills_icon {
    display: none;
}
@media only screen and (max-width: 768px) {
    .filter_box {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        position: fixed;
        right: 0px;
        top: 0px;
        z-index: 999;
        padding: 20px;
        display: block;
    }

    .filt_head_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filt_content_box {
        margin-top: 20px;
    }

    .filt_label {
        font-size: 14px;
        font-weight: 600;
        color: #265936;
        margin-bottom: 5px;
    }

    .fills_control {
        font-size: 14px;
    }

    .mob_padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mob_fil_align {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px;
    }

    .mob_fills_icon {
        display: block;
    }
}